<template>
  <div class="chart-shell" v-show="displayInfo.isChecked">
    <div class="chart-shell-content">
      <div class="chart-shell-content-head">
        <div class="chart-shell-content-head-left">
          <p class="chart-shell-content-head-left-title">
            {{ unit ? `${title}（${unit}）` : title }}
          </p>
          <slot name="left-content"></slot>
        </div>
        <time-span-picker v-if="isFullFunctional" :buttonArray="buttonArray" :inputSelectedIndex="inputSelectedIndex"
          @onTimeSpanPicked="onTimeSpanPicked"></time-span-picker>
      </div>
      <slot class="chart-shell-content-chart" name="chart"></slot>
      <slider v-if="isFullFunctional" class="chart-shell-content-slider" :barWidth="timeSelectBarWidth"
        :style="{ '--width': `${timeSelectBarWidth}px` }" :dataArray="timeBarArray"
        :selectedTimeSpan="buttonArray[selectedIndex].value" :inputExtremeTimeStampArray="inputExtremeTimeStampArray"
        @onIntervalSelected="onIntervalSelected"></slider>
    </div>
    <!-- 分页 -->
    <div v-if="isFullFunctional">
      <div v-if="displayInfo.needBreak.length">
        <print-footer :firstPage="displayInfo.needBreak[0]" :totalPage="displayInfo.needBreak[1]" :marginTop="460" />
        <div style="page-break-after: always"></div>
        <print-header v-if="isShowPrintDisplayHeader" :info="printHeaderInfo" />
      </div>
    </div>
  </div>
</template>

<script>
import TimeSpanPicker from "@c/report/charts/chart/single-chart/chart-shell/time-span-picker.vue";
import Slider from "@c/report/charts/chart/single-chart/chart-shell/slider.vue";
import PrintHeader from "@c/report/print/print-header.vue";
import PrintFooter from "@c/report/print/print-footer.vue";
import { DateTool } from "@js/date-tool.js";
export default {
  components: {
    TimeSpanPicker,
    PrintHeader,
    PrintFooter,
    Slider,
  },

  props: {
    timeSelectBarWidth: Number,
    isFullFunctional: Boolean,
    title: String,
    unit: String,
    buttonArray: Array,
    timeBarArray: Array,
    inputSelectedIndex: Number,
    inputExtremeTimeStampArray: Array,
    displayInfoArray: Array,
    printHeaderInfo: Object,
  },

  data() {
    return {
      selectedIndex: 0,
      extremeStrArray: [],
      displayInfo: null,
    };
  },

  created() {
    this.selectedIndex = this.inputSelectedIndex;
    this.extremeStrArray = this.inputExtremeTimeStampArray.map((item) => {
      return DateTool.milliTimestampToStr(item, "HH:mm:ss");
    });
    this.displayInfo = this.displayInfoArray.find((item) => {
      return item.name == this.title;
    });
  },

  watch: {
    inputExtremeTimeStampArray(value) {
      this.extremeStrArray = value.map((item) => {
        return DateTool.milliTimestampToStr(item, "HH:mm:ss");
      });
    },

    displayInfoArray(value) {
      this.displayInfo = value.find((item) => {
        return item.name == this.title;
      });
    }
  },

  methods: {
    onTimeSpanPicked(index) {
      this.selectedIndex = index;
      this.$emit("onTimeSpanPicked", index);
    },

    onIntervalSelected(intervalArray) {
      this.$emit("onIntervalSelected", intervalArray);
    },
  },

  computed: {
    isShowPrintDisplayHeader() {
      return this.displayInfo.needBreak[0] != this.displayInfo.needBreak[1];
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-shell {
  margin-top: 20px;

  &-content {
    border: 1px solid #e8e8e8;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center; 强行对齐会引起图标位置偏移

    &-head {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &-left {
        display: flex;
        align-items: center;

        &-title {
          color: black;
          font-size: 16px;
          margin-right: 7px;
        }
      }
    }

    &-chart {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-content {
        width: 100%;
      }
    }

    &-slider {
      margin-top: 10px;
      width: var(--width);
      align-self: center;
    }
  }
}
</style>