<template>
  <chart-shell :timeSelectBarWidth="timeSelectBarWidth" :isFullFunctional="isFullFunctional" :title="title"
    :buttonArray="buttonArray" :timeBarArray="timeBarArray" :inputSelectedIndex="inputSelectedIndex"
    :inputExtremeTimeStampArray="inputExtremeTimeStampArray" :displayInfoArray="chartsDisplayInfoArray"
    :printHeaderInfo="printHeaderInfo" @onTimeSpanPicked="onTimeSpanPicked" @onIntervalSelected="onIntervalSelected">
    <template v-slot:chart>
      <div class="bar-chart-event" :id="title" :style="`width: ${chartWidth}px; height: ${250 * zoom}px ;zoom:${1 / zoom
        };transform:scale(${zoom});transform-origin:0 0`"></div>
    </template>
  </chart-shell>
</template>

<script>
import ChartShell from "@c/report/charts/chart/single-chart/chart-shell/chart-shell.vue";
export default {
  components: {
    ChartShell,
  },

  props: {
    chartWidth: {
      type: Number,
      default: 858,
    },
    timeSelectBarWidth: {
      type: Number,
      default: 740,
    },
    isFullFunctional: Boolean,
    buttonArray: Array,
    barChartDataArray: Array,
    timeBarArray: Array,
    inputSelectedIndex: Number,
    inputExtremeTimeStampArray: Array,
    chartsDisplayInfoArray: Array,
    printHeaderInfo: Object,
  },

  data() {
    return {
      title: "事件",
      chart: null,
    };
  },

  computed: {
    zoom() {
      return this.$store.state.zoom
    },

    placeHolderArray() {
      return this.barChartDataArray.map((item) => {
        return [item[0], this.pickPlaceHolderHeight(item[1])];
      });
    },

    dataArray() {
      return this.barChartDataArray.map((item) => {
        return [item[0], item[1] ? 200 : null];
      });
    },
  },

  mounted() {
    this.chart = this.$echarts.init(document.getElementById(this.title));
    this.drawLine();
  },

  watch: {
    barChartDataArray() {
      this.updateLine();
    },
  },

  methods: {
    drawLine() {
      this.chart.setOption({
        grid: {
          top: 20,
          left: 65,
          right: 65,
          bottom: 20,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: (value) => {
              return this.$echarts.format.formatTime("hh:mm", new Date(value));
            },
          },
        },
        yAxis: {
          min: 0,
          max: 900,
          interval: 300,
          axisLabel: {
            formatter: function (value) {
              var texts = [];
              if (value == 0) {
                texts.push("");
              } else if (value <= 300) {
                texts.push("OSA");
              } else if (value <= 600) {
                texts.push("Hypopnea");
              } else if (value <= 900) {
                texts.push("CSA");
              } else {
                texts.push("");
              }
              return texts;
            },
          },
        },
        series: [
          {
            name: "Placeholder",
            type: "bar",
            symbol: "none", //点点
            stack: "Total", //堆叠
            barCategoryGap: "0%", //间距
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            data: this.placeHolderArray,
          },
          {
            name: "Event",
            type: "bar",
            symbol: "none",
            stack: "Total",
            data: this.dataArray,
            barCategoryGap: "0%",
            itemStyle: {
              color: (params) => {
                return this.pickColor(params.dataIndex);
              },
            },
          },
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: (arg) => {
            return `${arg[0].axisValueLabel}`;
          },
        },
      });
    },

    updateLine() {
      this.chart.setOption({
        series: [
          {
            data: this.placeHolderArray,
          },
          {
            data: this.dataArray
          },
        ],
      });
    },

    pickColor(index) {
      let barColor = "";
      switch (this.barChartDataArray[index][1]) {
        case "oas":
          barColor = "#91cc75"; //绿
          break;
        case "hypopnea":
          barColor = "#ee6666"; //红
          break;
        case "csa":
          barColor = "#fac858"; //黄
          break;

        default:
          break;
      }
      return barColor;
    },

    pickPlaceHolderHeight(eventType) {
      let height = "";
      switch (eventType) {
        case "oas":
          height = "50";
          break;
        case "hypopnea":
          height = "350";
          break;
        case "csa":
          height = "650";
          break;
        default:
          break;
      }
      return height;
    },

    onTimeSpanPicked(index) {
      this.$emit("onTimeSpanPicked", index);
    },

    onIntervalSelected(extremeTimeStampArray) {
      this.$emit("onIntervalSelected", extremeTimeStampArray);
    },
  },


};
</script>

<style lang="scss" scoped>
.bar-chart-event {
  @include z-index-2;
}
</style>