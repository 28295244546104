<template>
  <chart-shell :timeSelectBarWidth="timeSelectBarWidth" :isFullFunctional="isFullFunctional" :title="model.title"
    :unit="model.unit" :timeBarArray="timeBarArray" :buttonArray="buttonArray" :inputSelectedIndex="inputSelectedIndex"
    :inputExtremeTimeStampArray="inputExtremeTimeStampArray" :displayInfoArray="chartsDisplayInfoArray"
    :printHeaderInfo="printHeaderInfo" @onTimeSpanPicked="onTimeSpanPicked" @onIntervalSelected="onIntervalSelected">
    <template v-slot:left-content>
      <chart-selector v-model="selectedInnerChartIndex"></chart-selector>
    </template>
    <template v-slot:chart>
      <div class="line-chart" :id="model.title" :style="`width: ${chartWidth}px; height: ${250 * zoom}px ;zoom:${1 / zoom
        };transform:scale(${zoom});transform-origin:0 0`"></div>
    </template>
  </chart-shell>
</template>

<script>
import ChartShell from "@c/report/charts/chart/single-chart/chart-shell/chart-shell.vue";
import ChartSelector from "@c/report/charts/chart/single-chart/line-chart-pressure/chart-selector.vue";

export default {
  components: {
    ChartShell,
    ChartSelector,
  },

  props: {
    chartWidth: {
      type: Number,
      default: 858,
    },
    timeSelectBarWidth: {
      type: Number,
      default: 740,
    },
    reportModel: Object,
    isFullFunctional: Boolean,
    buttonArray: Array,
    timeBarArray: Array,
    model: Object,
    lineChartPressureArray: Array,
    inputSelectedIndex: Number,
    inputExtremeTimeStampArray: Array,
    chartsDisplayInfoArray: Array,
    printHeaderInfo: Object,
  },

  data() {
    return {
      chart: null,
      selectedInnerChartIndex: 0,
      series: [],
    };
  },

  computed: {
    zoom() {
      return this.$store.state.zoom
    },

    pressure90() {
      return this.reportModel.model.report.tpIn;
    },

    mode() {
      const parameterArray = this.reportModel.model.parameter;
      for (const item of parameterArray) {
        if (item.paramKey == "cure_mode") {
          console.log("🧈",item.paramLabel)
          return item.paramLabel;
        }
      }
    },
  },

  watch: {
    model() {
      console.log(this.mode)
      this.drawLine();
    },

    lineChartPressureArray() {
      this.drawLine();
    },

    selectedInnerChartIndex() {
      this.drawLine();
    },
  },

  mounted() {
    this.chart = this.$echarts.init(document.getElementById(this.model.title));
    this.drawLine();
  },

  methods: {
    drawLine() {
      this.chart.setOption({
        grid: { top: 20, left: 65, right: 65, bottom: 20 },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: (value) => {
              return this.$echarts.format.formatTime("hh:mm", new Date(value));
            },
          },
        },
        yAxis: {
          type: "value",
          max: this.mode == "CPAP" || this.mode == "APAP" ? 20 : 30,
        },
        series: this.computeSeries(),
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: (arg) => {
            const timeStr = arg[0].axisValueLabel
            const value = arg[0].data[1]
            if (value) {
              return `${timeStr}<br />${value} ${this.model.unit}`;
            } else {
              return ""
            }
          },
        },
      });
    },
    
    onTimeSpanPicked(index) {
      this.$emit("onTimeSpanPicked", index);
    },

    onIntervalSelected(extremeTimeStampArray) {
      this.$emit("onIntervalSelected", extremeTimeStampArray);
    },

    computeSeries() {
      if (this.selectedInnerChartIndex == 0) {
        const breathInArray = this.lineChartPressureArray.map((item) => {
          return [item[0], item[1]];
        });
        const seriesItem1 = this.computeSeriesItem(breathInArray);
        seriesItem1.lineStyle.color = "#5372C0";
        const breathOutArray = this.lineChartPressureArray.map((item) => {
          return [item[0], item[2]];
        });
        const seriesItem2 = this.computeSeriesItem(breathOutArray);
        seriesItem2.lineStyle.color = "#20A2F3";
        return [seriesItem1, seriesItem2];
      } else {
        const seriesItem = this.computeSeriesItem(this.model.dataArray);
        return [seriesItem];
      }
    },

    computeSeriesItem(dataArray) {
      return {
        type: "line",
        symbol: "none",
        smooth: true,
        data: dataArray,
        lineStyle: { width: 1 },
        connectNulls: true,
        emphasis: {
          lineStyle: { width: 1 },
        },
        markLine: {
          symbol: "none",
          data: [
            {
              yAxis: parseFloat(this.pressure90),
            },
          ],
          lineStyle: {
            color: "#0F92F7",
          },
          label: {
            position: "middle",
            formatter: (item) => {
              return `${item.value} ${this.model.unit}`;
            },
          },
        },
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.chart-picker {
  background-color: purple;
  width: 200px;
  height: 100%;
}

.line-chart {
  @include z-index-2;
}
</style>