<template>
  <div class="time-span-picker">
    <button
      class="item time-span-picker-item"
      v-for="(item, index) of buttonArray.slice(0, 6)"
      :key="index"
      @click="onTimeSpanPicked(index)"
    >
      {{ item.title }}
    </button>
    <button class="item time-span-picker-arrow-item" @click="onButtonClick()">
      <p>{{ buttonArray[arrowButtonIndex].title }}</p>
      <img
        class="time-span-picker-arrow-item-icon"
        :class="
          isShowTimeSpanList
            ? 'time-span-picker-arrow-item-icon-open'
            : 'time-span-picker-arrow-item-icon-close'
        "
        :src="arrowIcon"
      />
    </button>
    <div v-if="isShowTimeSpanList" class="time-span-picker-list">
      <button
        class="item time-span-picker-list-item"
        v-for="(item, index) of buttonArray.slice(6, 11)"
        :key="index"
        @click="onTimeSpanPicked(index + 6)"
      >
        {{ item.title }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    buttonArray: Array,
    inputSelectedIndex: Number,
  },

  data() {
    return {
      selectedIndex: null,
      isShowTimeSpanList: false,
      arrowButtonIndex: this.buttonArray.length - 1,
    };
  },

  computed: {
    arrowIcon() {
      return this.selectedIndex == this.arrowButtonIndex
        ? require("@imgs/report-arrow-white.png")
        : require("@imgs/report-arrow-black.png");
    },
  },

  created() {
    this.selectedIndex = this.inputSelectedIndex;
  },

  watch: {
    inputSelectedIndex(value) {
      this.selectedIndex = value;
    },

    selectedIndex(value) {
      if (value > 5) {
        this.arrowButtonIndex = this.selectedIndex;
      }
    },
  },

  methods: {
    // action
    onTimeSpanPicked(index) {
      this.isShowTimeSpanList = false;
      this.selectedIndex = index;
      this.$emit("onTimeSpanPicked", index);
      if (index > 5) {
        this.arrowButtonIndex = this.selectedIndex;
      }
    },

    onButtonClick() {
      this.isShowTimeSpanList = !this.isShowTimeSpanList;
    },
  },
};
</script>

<style lang="scss" scoped>
.time-span-picker {
  position: relative;
  display: flex;
  justify-content: space-between;

  &-item {
    margin-right: 20px;
  }

  &-arrow-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    &-icon {
      width: 8.6px;
      height: 4.9px;
      margin-left: 4px;

      &-open {
        transform: rotate(-180deg);
        transition: all 0.3s;
      }

      &-close {
        transform: rotate(0deg);
        transition: all 0.3s;
      }
    }
  }

  &-list {
    @include z-index-max;
    position: absolute;
    right: 20px;
    top: 25px;
    display: flex;
    flex-direction: column;

    &-item {
      margin-top: 1px;
      padding-right: 12px;
    }
  }
}

.item {
  color: #666666;
  background-color: #f7f7f7;
  font-size: 12px;
  width: 40px;
  height: 25px;

  &:hover {
    color: white;
    background-color: #1890fb;
  }
}
</style>