import { DateTool } from "@js/date-tool.js";
class ChartModel {
    // constructor
    constructor(model) {
        this.model = model;
        this.mode = model.mode
        this.timeArray = this._initTimeArray()
        this.timeBarInterval = this.model.skip
        this.lineChartPressureArray = this._initLineChartPressureArray()
        this.lineChartsArray = this._initLineChartsArray()
        this.barChartEventArray = this._initBarCharEventArray()
        this.liteLineChartsArray = this._initLiteLineChartsArray()
    }

    // init
    _initTimeArray() {
        return this.model.chartData.map((item) => {
            return item[0]
        })
    }

    _initLiteLineChartsArray() {
        let chartsArray = []
        const titleArray = ["压力", "漏气"]
        const unitArray = ["cm H2O", "L/min"]
        for (let index = 1; index <= 2; index++) {
            const chartData = {
                title: titleArray[index - 1],
                unit: unitArray[index - 1],
                dataArray: this.model.chartData.map((item) => {
                    return [item[0], item[index == 1 ? 1 : 3]]
                })
            }
            chartsArray.push(chartData)
        }
        return chartsArray
    }

    _initLineChartsArray() {
        let chartsArray = []
        const titleArray = ["压力", "流量", "漏气", "潮气量", "呼吸率", "分钟通气量", "血氧", "心率"]
        const unitArray = ["cm H2O", "L/min", "L/min", "ML", "bpm", "L/min", "%", "bpm"]
        for (let index = 1; index < 9; index++) {
            let dataArray
            switch (index) {
                case 8: //心率
                    dataArray = this._composeSPO2Array(this.model.prArr)
                    break
                case 7: //血氧
                    dataArray = this._composeSPO2Array(this.model.o2Arr)
                    break
                default:
                    dataArray = this.model.chartData.map((item) => {
                        return [item[0], item[index]];
                    })
            }
            const chartData = {
                title: titleArray[index - 1],
                unit: unitArray[index - 1],
                dataArray: dataArray
            }
            chartsArray.push(chartData)
        }
        return chartsArray
    }

    _composeSPO2Array(rawDataArray) {
        //创建一个[时间戳,null]数组
        const rawStartTimestamp = this.model.chartData[0][0]
        const startTimestamp = parseInt(rawStartTimestamp / 1000) * 1000
        const rawEndTimestamp = this.model.chartData[this.model.chartData.length - 1][0]
        const endTimestamp = parseInt(rawEndTimestamp / 1000) * 1000
        let dataMap = new Map()
        for (let timestamp = startTimestamp; timestamp <= endTimestamp; timestamp += 1000) {
            dataMap.set(timestamp, null)
        }
        rawDataArray?.forEach((item) => {
            const timestamp = item[0]
            if (dataMap.has(timestamp)) {
                dataMap.set(timestamp, item[1])
            }
        })
        let dataArray = Array.from(dataMap)
        return dataArray
    }

    _initLineChartPressureArray() {
        //创建一个[时间戳,null]数组
        const dataArray = this.model.chartData.map((item) => {
            return [item[0], null, null]
        })
        this.model.pressureEvent?.forEach(item => {
            const timeStamp = item[0]
            const index = this._computeTimeIndex(timeStamp)
            if (index < dataArray.length) {
                dataArray[index][1] = item[1] == 0 ? null : item[1] / 10
                dataArray[index][2] = item[2] == 0 ? null : item[2] / 10
            }
        });
        return dataArray
    }

    _initBarCharEventArray() {
        //创建一个[时间戳,null]数组
        const dataArray = this.model.chartData.map((item) => {
            return [item[0], null]
        })
        this._markEvent(dataArray, "oas")
        this._markEvent(dataArray, "csa")
        this._markEvent(dataArray, "hypopnea")
        return dataArray
    }

    //在发生特定事件的时间戳上进行标记
    _markEvent(dataArray, eventName) {
        let sourceDataArray = []
        switch (eventName) {
            case "oas":
                sourceDataArray = this.model.oas
                break;
            case "csa":
                sourceDataArray = this.model.csa
                break;
            case "hypopnea":
                sourceDataArray = this.model.hypopnea
                break;
            default:
                break;
        }
        for (const item of sourceDataArray) {
            //计算事件开始时间戳在图表时间轴中的index
            const time = item[0]
            const startIndex = this._computeTimeIndex(time)
            //计算呼吸事件事件在图表时间轴中的持续跨度
            const span = Math.round(item[1] * 1000 / this.timeBarInterval) //item[1]的单位为秒
            //修改数组
            for (let index = startIndex; index < startIndex + span; index++) {
                if (dataArray[index]) {
                    dataArray[index][1] = eventName
                }
            }
        }
    }

    //计算时间戳在图表时间轴中的index
    _computeTimeIndex(timeStamp) {
        const timeGap = timeStamp - this.timeArray[0]
        const timeIndex = Math.round(timeGap / this.timeBarInterval)
        return timeIndex
    }

}


export { ChartModel };
