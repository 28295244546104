<template>
  <chart-shell :timeSelectBarWidth="timeSelectBarWidth" :isFullFunctional="isFullFunctional" :title="model.title"
    :unit="model.unit" :timeBarArray="timeBarArray" :buttonArray="buttonArray" :inputSelectedIndex="inputSelectedIndex"
    :inputExtremeTimeStampArray="inputExtremeTimeStampArray" :displayInfoArray="chartsDisplayInfoArray"
    :printHeaderInfo="printHeaderInfo" @onTimeSpanPicked="onTimeSpanPicked" @onIntervalSelected="onIntervalSelected">
    <template v-slot:chart>
      <div class="line-chart" :id="model.title" :style="`width: ${chartWidth}px; height: ${250 * zoom}px ;zoom:${1 / zoom
        };transform:scale(${zoom});transform-origin:0 0`"></div>
    </template>
  </chart-shell>
</template>

<script>
import ChartShell from "@c/report/charts/chart/single-chart/chart-shell/chart-shell.vue";
export default {
  components: {
    ChartShell,
  },

  props: {
    chartWidth: {
      type: Number,
      default: 858,
    },
    timeSelectBarWidth: {
      type: Number,
      default: 740,
    },
    isFullFunctional: Boolean,
    buttonArray: Array,
    timeBarArray: Array,
    model: Object,
    inputSelectedIndex: Number,
    inputExtremeTimeStampArray: Array,
    chartsDisplayInfoArray: Array,
    printHeaderInfo: Object,
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {
    zoom() {
      return this.$store.state.zoom
    },

    yAxis() {
      switch (this.model.title) {
        case "血氧":
          return {
            type: "value",
            min: 65,
            max: 100,
            axisLabel: {
              formatter: function (value, index) {
                return value + "%";
              },
            },
          };
        case "心率":
          return {
            type: "value",
            min: 40,
            max: 120,
          };
        default:
          return {
            type: "value",
          };
      }
    },

    markLine() {
      switch (this.model.title) {
        case "漏气":
          return {
            symbol: "none",
            data: [
              {
                yAxis: 5,
              },
              {
                yAxis: 100,
              },
            ],
            lineStyle: {
              color: "#F45C52",
            },
            label: {
              position: "middle",
              formatter: (item) => {
                return `${item.value} ${this.model.unit}`;
              },
            },
          };

        default:
          break;
      }
    },
  },


  watch: {
    model() {
      this.updateLine();
    },
  },

  mounted() {
    this.chart = this.$echarts.init(document.getElementById(this.model.title));
    this.drawLine();
  },

  methods: {
    drawLine() {
      this.chart.setOption({
        grid: {
          top: 20,
          left: 65,
          right: 65,
          bottom: 20,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: (value) => {
              return this.$echarts.format.formatTime("hh:mm", new Date(value));
            },
          },
        },
        yAxis: this.yAxis,
        series: [
          {
            type: "line",
            symbol: "none",
            smooth: true,
            data: this.model.dataArray,
            lineStyle: {
              width: 1, //设置线条粗细
            },
            emphasis: {
              lineStyle: {
                width: 1, //设置选中线条粗细
              },
            },
            markLine: this.markLine,
          },
        ],
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: (arg) => {
            const value = arg[0].data[1]
            if (value) {
              return `${arg[0].axisValueLabel}<br />${arg[0].data[1]} ${this.model.unit}`;
            } else {
              return ""
            }
          },
        },
      });
    },

    updateLine() {
      this.chart.setOption({
        series: [
          {
            data: this.model.dataArray,
          },
        ]
      });
    },
    onTimeSpanPicked(index) {
      this.$emit("onTimeSpanPicked", index);
    },

    onIntervalSelected(extremeTimeStampArray) {
      this.$emit("onIntervalSelected", extremeTimeStampArray);
    }

  }
}
</script>

<style lang="scss" scoped>
.line-chart {
  @include z-index-2;
}
</style>