<template>
  <div class="slider no-print" :style="`zoom:${1 / zoom
    };transform:scale(${zoom});transform-origin:50% 50%`" ref="sliderBar" @mousemove="onMouseMove" @mouseup="onMouseUp"
    @mouseleave="isStartButtonDown = false; isEndButtonDown = false">
    <!-- startButton -->
    <button class="slider-button" :style="{ left: startButtonLeft + 'px' }" @mousedown="isStartButtonDown = true"
      @mouseup="isStartButtonDown = false">
      {{ sliderButtonTitle }}
    </button>
    <div class="slider-label" :style="{ left: startLabelLeft + 'px' }">
      <div>{{ dataArray[startIndex]?.timeStr }}</div>
    </div>
    <!-- endButton -->
    <button class="slider-button" :style="{ left: endButtonLeft + 'px' }" @mousedown="isEndButtonDown = true"
      @mouseup="isEndButtonDown = false">
      {{ sliderButtonTitle }}
    </button>
    <div class="slider-label" :style="{ left: endLabelLeft + 'px' }">
      <div>{{ dataArray[endIndex]?.timeStr }}</div>
    </div>
    <!-- rangeButton -->
    <button class="slider-range-button" :style="{
      left: `${startButtonLeft + 14}` + 'px',
      width: `${rangeButtonWidth}` + 'px',
    }" @mousedown="isRangeButtonDown = true" @mouseup="isRangeButtonDown = false" />
  </div>
</template>
     
<script>
export default {
  props: {
    barWidth: Number,
    dataArray: Array,
    selectedTimeSpan: Number,
    inputExtremeTimeStampArray: Array,
  },

  data() {
    return {
      sliderButtonTitle: "|||",
      //startButton
      startButtonLeft: -14,
      startIndex: 0,
      isStartButtonDown: false,
      //endButton
      endButtonLeft: this.barWidth,
      endIndex: this.dataArray.length - 1,
      isEndButtonDown: false,
      //rangeButton
      isRangeButtonDown: false,
    };
  },

  computed: {
    zoom() {
      return this.$store.state.zoom
    },

    startLabelLeft() {
      return this.startButtonLeft - 60;
    },

    endLabelLeft() {
      return this.endButtonLeft + 14;
    },

    rangeButtonWidth() {
      return this.endButtonLeft - this.startButtonLeft - 14;
    },
  },

  watch: {
    selectedTimeSpan(timeSpan) {
      //dataArrayLength(参见本页“极限法分析”)
      const dataArrayLength = this.dataArray.length - 1;
      //totalTimeSpan
      const startTimeStamp = this.dataArray[0].timeStamp;
      const endTimeStamp = this.dataArray[dataArrayLength].timeStamp;
      const totalTimeSpan = endTimeStamp - startTimeStamp;
      //rangeIndexValue和rangeWidth
      let rangeIndexValue;
      let rangeWidth;
      if (timeSpan <= totalTimeSpan) {
        rangeIndexValue = Math.round(
          dataArrayLength * (timeSpan / totalTimeSpan)
        );
        rangeWidth = Math.round(this.barWidth * (timeSpan / totalTimeSpan));
      } else {
        rangeIndexValue = dataArrayLength;
        rangeWidth = this.barWidth;
      }
      //移动endButton
      if (this.startButtonLeft + 14 + rangeWidth <= this.barWidth) {
        this.endIndex = this.startIndex + rangeIndexValue;
        this.endButtonLeft = this.startButtonLeft + 14 + rangeWidth;
      } else {
        //endButton
        this.endButtonLeft = this.barWidth;
        this.endIndex = this.dataArray.length - 1;
        //startButton
        this.startButtonLeft = this.barWidth - rangeWidth - 14;
        this.startIndex = this.endIndex - rangeIndexValue;
        this.onMouseUp()
      }
    },

    inputExtremeTimeStampArray(value) {
      const timeStampArray = this.dataArray.map((item) => {
        return item.timeStamp;
      });
      //startButton
      this.startIndex = timeStampArray.indexOf(value[0]);
      //参见本页“极限法分析”
      this.startButtonLeft =
        this.barWidth * (this.startIndex / (this.dataArray.length - 1)) - 14;
      //endButton
      this.endIndex = timeStampArray.indexOf(value[1]);
      this.endButtonLeft =
        this.barWidth * (this.endIndex / (this.dataArray.length - 1));
    },
  },

  methods: {
    onMouseUp() {
      this.$emit("onIntervalSelected", [
        this.dataArray[this.startIndex].timeStamp,
        this.dataArray[this.endIndex].timeStamp,
      ]);
    },

    onMouseMove(e) {
      // isStartButtonDown
      if (this.isStartButtonDown) {
        /**
         * e.clientX，是指鼠标拖动的组件的中心相对于整个浏览器的left
         * this.$refs.sliderBar.getBoundingClientRect().left 是指sliderBar相对于整个浏览器的left
         * startButton和rangeButton以右边作为原点，因此要加上其宽度的一半
         * endButon以左边为原点，因此要减去其宽度的一半
         */
        let left =
          Math.round(
            e.clientX + 7 - this.$refs.sliderBar.getBoundingClientRect().left
          ) / this.zoom;
        const isToRight = this.startButtonLeft < left - 14;
        //startButton的0为dataArray的第一个元素,100为dataArray的倒数第二个元素，总数为dataArrayLength-1
        if (left <= 0) {
          this.startButtonLeft = -14;
          this.startIndex = 0;
        } else if (left >= this.barWidth) {
          this.startButtonLeft = this.barWidth - 14;
          this.startIndex = this.dataArray.length - 2;
        } else {
          this.startButtonLeft = left - 14;
          this.startIndex = Math.round(
            (this.dataArray.length - 1) * (left / this.barWidth)
          );
        }
        //顶到endButton
        if (isToRight && this.rangeButtonWidth <= 0) {
          this.endButtonLeft = this.startButtonLeft + 14;
          this.endIndex = this.startIndex + 1;
        }
      }
      // isEndButtonDown
      if (this.isEndButtonDown) {
        let left =
          Math.round(
            e.clientX - 7 - this.$refs.sliderBar.getBoundingClientRect().left
          ) / this.zoom;
        const isToLeft = this.endButtonLeft > left;
        //endButton的0为dataArray的第二个元素,100为dataArray的最后一个元素，总数为dataArrayLength-1
        if (left <= 0) {
          this.endButtonLeft = 0;
          this.endIndex = 1;
        } else if (left >= this.barWidth) {
          this.endButtonLeft = this.barWidth;
          this.endIndex = this.dataArray.length - 1;
        } else {
          this.endButtonLeft = left;
          this.endIndex = Math.round(
            (this.dataArray.length - 1) * (left / this.barWidth)
          );
        }
        //顶到startButton
        if (isToLeft && this.rangeButtonWidth <= 0) {
          this.startButtonLeft = this.endButtonLeft - 14;
          this.startIndex = this.endIndex - 1;
        }
      }
      //isRangeButtonDown
      if (this.isRangeButtonDown) {
        this.isStartButtonDown = false;
        this.isEndButtonDown = false;
        /**
         * rangeButtonWidth虽然由startButtonLeft和endButtonLeft计算而来，但是不能将它设做计算属性。
         * 因为还要由它来计算startButtonLeft和endButtonLeft
         * 否则会引起连锁反应，达不到想要的效果
         */
        const rangeButtonWidth = this.endButtonLeft - this.startButtonLeft - 14;
        let left =
          Math.round(
            e.clientX -
            rangeButtonWidth / 3 -
            this.$refs.sliderBar.getBoundingClientRect().left
          ) / this.zoom; //除以3是实验值
        /**
         * totalIndexValue该用this.dataArray.length，还是this.dataArray.length - 1
         * 可以采用极限法分析。
         * 当rangeButonWidth = barWidth时，若rangeIndexValue == this.dataArray.length - 1
         * 滑到左边：startIndex = 0，endIndex = this.startIndex + rangeIndexValue
         * 滑到右边：endIndex = this.dataArray.length - 1，startIndex = 0
         * 均可以取到值
         */
        const totalIndexValue = this.dataArray.length - 1;
        const rangeIndexValue = Math.round(
          totalIndexValue * (rangeButtonWidth / this.barWidth)
        );
        if (left <= 0) {
          this.startButtonLeft = -14;
          this.startIndex = 0;
          this.endButtonLeft = rangeButtonWidth;
          this.endIndex = this.startIndex + rangeIndexValue;
        } else if (left >= this.barWidth - rangeButtonWidth) {
          this.endButtonLeft = this.barWidth;
          this.endIndex = this.dataArray.length - 1;
          this.startButtonLeft = this.barWidth - rangeButtonWidth - 14;
          this.startIndex = this.endIndex - rangeIndexValue;
        } else {
          this.startButtonLeft = left - 14;
          this.startIndex = Math.round(
            (this.dataArray.length - 1) * (left / this.barWidth)
          );
          this.endButtonLeft = left + rangeButtonWidth;
          this.endIndex = this.startIndex + rangeIndexValue;
        }
      }
    },
  }
};
</script>
     
<style lang="scss" scoped>
@media print {
  .no-print {
    display: none !important;
  }
}

.slider {
  width: 100%;
  height: 30px;
  background-color: #f7f7f7;

  &-button {
    position: absolute;
    z-index: 10;
    color: #a1a1a6;
    background-color: #c3c3c3;
    width: 14px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-range-button {
    position: absolute;
    z-index: 10;
    background-color: #dceefe;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-label {
    position: absolute;
    width: 60px;
    height: 30px;
    color: #515154;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>