<template>
  <div class="chart-selector">
    <div
      class="chart-selector-item"
      v-for="(item, index) of dataArray"
      :key="index"
      @click="onItemClick(index)"
    >
      <div
        class="chart-selector-item-icon"
        :class="[
          index === selectedIndex
            ? 'chart-selector-item-icon-blue'
            : 'chart-selector-item-icon-gray',
        ]"
      />
      <p class="chart-selector-item-title">{{ item }}</p>
    </div>
  </div>
</template>
  
  <script>
export default {
  model: {
    prop: "inputSelectedIndex",
    event: "onSelected",
  },

  props: {
    inputSelectedIndex: Number,
  },

  data() {
    return {
      dataArray: ["调压数据", "详细数据"],
      selectedIndex: undefined,
    };
  },

  created() {
    this.selectedIndex = this.inputSelectedIndex;
  },

  watch: {
    inputSelectedIndex(value) {
      this.selectedIndex = value;
    },
  },

  methods: {
    onItemClick(index) {
      this.$emit("onSelected", index);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.chart-selector {
  display: flex;
  align-items: center;

  &-item {
    display: flex;
    align-items: center;
    margin-right: 15px;

    &-icon {
      width: 10px;
      height: 10px;

      &-blue {
        background-color: #5273bd;
      }

      &-gray {
        background-color: #c1c1c1;
      }
    }

    &-title {
      color: #666666;
      font-size: 14px;
      margin-left: 5px;
    }
  }
}
</style>
  
  
  